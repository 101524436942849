import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/flomesh.io/flomesh.io/src/layouts/Fixed/DocMdx.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "new-design"
    }}>{`New Design`}</h1>
    <ul>
      <li parentName="ul">{`Start a Pipy instance remotely from HTTP endpoints`}</li>
      <li parentName="ul">{`Builtin codebase service as a basic control plane, and its GUI too`}</li>
      <li parentName="ul">{`Data exchange between PJS modules can be done through `}<inlineCode parentName="li">{`pipy().import()`}</inlineCode>{` and `}<inlineCode parentName="li">{`pipy().export()`}</inlineCode>{`, which becomes the recommended approach over the old ones`}</li>
    </ul>
    <h1 {...{
      "id": "new-filters"
    }}>{`New Filters`}</h1>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`merge`}</inlineCode>{` for interweaving multiple messages into one stream`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`pack`}</inlineCode>{` for combining multiple messages into one`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`split`}</inlineCode>{` for byte-wise processing of messages`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`handleXXX`}</inlineCode>{` as aliases to `}<inlineCode parentName="li">{`onXXX`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`proxySOCKS`}</inlineCode>{` to handle both SOCKS4 and SOCKS5 traffic`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`connectTLS`}</inlineCode>{` and `}<inlineCode parentName="li">{`acceptTLS`}</inlineCode>{` for TLS traffic handling, extracted from `}<em parentName="li">{`listen`}</em>{` and `}<em parentName="li">{`connect`}</em></li>
      <li parentName="ul"><inlineCode parentName="li">{`decompressMessage`}</inlineCode>{` and `}<inlineCode parentName="li">{`decompressHTTP`}</inlineCode>{` for message body decompression by zlib`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`demuxHTTP`}</inlineCode>{` and `}<inlineCode parentName="li">{`muxHTTP`}</inlineCode>{` combining HTTP codec and mux/demux`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`serveHTTP`}</inlineCode>{` combining `}<em parentName="li">{`demuxHTTP`}</em>{` and `}<em parentName="li">{`replaceMessage`}</em>{` for convenience`}</li>
    </ul>
    <h1 {...{
      "id": "new-api"
    }}>{`New API`}</h1>
    <ul>
      <li parentName="ul">{`Function `}<inlineCode parentName="li">{`os.writeFile()`}</inlineCode>{` for local file writing`}</li>
      <li parentName="ul">{`Function `}<inlineCode parentName="li">{`algo.hash()`}</inlineCode>{` for JS value hashing`}</li>
      <li parentName="ul">{`Class `}<inlineCode parentName="li">{`Netmask`}</inlineCode>{` for IP address range handling`}</li>
      <li parentName="ul">{`Class `}<inlineCode parentName="li">{`algo.Percentile`}</inlineCode>{` for percentile calculation`}</li>
      <li parentName="ul">{`Class `}<inlineCode parentName="li">{`PublicKey`}</inlineCode>{`, `}<inlineCode parentName="li">{`PrivateKey`}</inlineCode>{`, `}<inlineCode parentName="li">{`Sign`}</inlineCode>{`, `}<inlineCode parentName="li">{`Verify`}</inlineCode>{`, `}<inlineCode parentName="li">{`Cipher`}</inlineCode>{`, `}<inlineCode parentName="li">{`Decipher`}</inlineCode>{` under namespace crypto`}</li>
    </ul>
    <h1 {...{
      "id": "improvements"
    }}>{`Improvements`}</h1>
    <ul>
      <li parentName="ul">{`IPv6 support`}</li>
      <li parentName="ul">{`Number of concurrent connections can be limited on `}<inlineCode parentName="li">{`listen`}</inlineCode></li>
      <li parentName="ul">{`Added size limit option to filters involving internal data buffers`}</li>
      <li parentName="ul">{`Track data buffer usage and concurrent inbound/outbound connections and dump on SIGTSTP`}</li>
      <li parentName="ul">{`HTTP codec overhaul, with the old 256KB fixed buffer optimized out`}</li>
      <li parentName="ul">{`Class `}<inlineCode parentName="li">{`algo.Cache`}</inlineCode>{` now has a limit to the number of entries, with old entries getting erased based on LRU policy`}</li>
      <li parentName="ul">{`Dubbo request ID becomes a string for its 64-bit range to be handled in JS`}</li>
      <li parentName="ul">{`Standard-conforming processing of object key ordering in PJS`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      